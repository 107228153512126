<route>
{
   "meta":{
      "requiresAuth":false
   }
}
</route>
<template>
  <router-view></router-view>
</template>

<script>
// @ is an alias to /src
// import GuestLandingPage from '@/modules/guests/pages/GuestLandingPage.vue';

export default {
  name: 'guest',
  // components: {
  //   GuestLandingPage,
  // },
  layout: 'AppMain',
};
</script>
